import React from "react";

const CenteredTitle = ({ children, size, animationType }) => {
    return (
        <div id="main-column" className={`column is-flex content is-vcentered is-fullheight ${animationType || ""}`}>
            <div className="container has-text-centered ">
                <span className={`${size} black-text garam`}>
                    {children}
                </span>
            </div>
        </div>
    )
}

export default CenteredTitle