import React from "react";
import { graphql } from "gatsby";
import LayoutArraiolos from "../components/LayoutArraiolos";
import CenteredTitle from "../components/CenteredTitle";
import { useIsNewPageVisit } from "../components/helpers/useIsNewPageVisit";
import { SEO } from "../components/Seo";
import PageTitles from "../components/helpers/pageTitles";

const IndexPage = ({ data, location }) => {
  const { backgroundImages } = data.markdownRemark.frontmatter;

  const [fadeInTitle, setFadeInTitle] = React.useState(false);
  const fromContactsPage = location?.state?.fromNavbar === PageTitles.CONTACTS;

  const isNewPageVisit = useIsNewPageVisit(location);

  const animationType = fromContactsPage ? "" :
    !isNewPageVisit || fadeInTitle ? "fade-in" : "delayed-fade-in";

  return (
    <LayoutArraiolos
      pageTitle={PageTitles.HOMEPAGE}
      displayBackground={isNewPageVisit}
      skipNavbarAnimation={!isNewPageVisit}
      backgroundImages={backgroundImages}
      setFadeInTitle={setFadeInTitle}
      sideColumnsSize="is-one-fifth"
      forceNoOverflow
    >
      <CenteredTitle size="large-text smaller-line-height main-title" animationType={animationType} isMainTitle>
        arraiolos associados
      </CenteredTitle>
    </LayoutArraiolos>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter
       {
        backgroundImages {
          image {
                  childImageSharp {gatsbyImageData(quality: 100)} 
        }
      }
    }
  }
}
`;
